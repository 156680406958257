@import "./mixins.scss";
// Font
$main-font: "Montserrat", sans-serif;
$mobMenu: 768;

:root {
	--color-primary: #013479;
	--color-primary_hover: #01285d;
	--color-secondary: #fef100;
	--color-bg: #f2f2f2;
	--color-white: #ffffff;

	@include rmin(1600) {
		--headerGap: 130px;
	}
	@include rIn(1360, 1599) {
		@include css-lock(80, 130, 1369, 1599, --headerGap);
	}
	@include r(1359) {
		@include css-lock(20, 80, 450, 1360, --headerGap);
	}
}

$main-font-size: 16px;
$main-font-weight: 400;
$main-line-height: 1.5;

// Transitions
$transition-base: 0.33s cubic-bezier(0.23, 1, 0.32, 1);
$transition-slow: 0.6s cubic-bezier(0.08, 1, 0.21, 1);
