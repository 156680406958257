.inner-page {
	padding: 50px var(--headerGap);
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;

	h3 {
		font-size: 30px;
		@include r(575) {
			font-size: 20px;
		}
	}

	p {
		font-size: 18px;
		@include r(575) {
			font-size: 14px;
		}
	}
}

.policy-page {
	margin-left: auto;
	margin-right: auto;
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
	width: 100%;
	padding-left: 1.875em;
	padding-right: 1.875em;
	@include rmin(992) {
		padding-left: 0;
		padding-right: 0;
		max-width: 87.5vw;
	}
}
